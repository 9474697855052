<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-md-12">
          <div class="card gutter-b">
            <div class="card-body">
              <div class="row">
                <b-form-group id="job_no-group" label="Job No." label-for="job_no" class="col-md-3">
                  <b-form-input id="job_no" v-model="jobNo"></b-form-input>
                </b-form-group>
                <div class="col-lg-10">
                  <submit-button :loading="loading" text="Submit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card gutter-b">
        <div class="card-body">
          <b-table striped hover :items="result" :fields="fields">
            <template #cell(action_at)="data">
              {{ formatISO9075(new Date(data.item.action_at)) }}
            </template>
          </b-table>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { formatISO9075 } from "date-fns";

import SubmitButton from "@/components/button/SubmitButton";

import { listTracking } from "@/api/job.api";

export default {
  name: "TrackJob",
  components: {
    SubmitButton
  },
  setup() {
    const loading = ref(false);
    const jobNo = ref();
    const result = ref([]);

    const onSubmit = async () => {
      loading.value = true;
      const { data } = await listTracking(jobNo.value);

      result.value = data;
      loading.value = false;
    };

    return {
      jobNo,
      result,
      onSubmit,

      loading,
      fields: [
        { key: "job_id", label: "Job No." },
        { key: "audit_trail", label: "Description" },
        { key: "status", label: "Status" },
        { key: "action_by", label: "Action By" },
        { key: "action_at", label: "Date/time" }
      ],
      formatISO9075
    };
  }
};
</script>
